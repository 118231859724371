import React from 'react';

export default () => {
  return <></>;
  //return isEmpty(notificationPreferences) ? (
  //  <></>
  //) : (
  //  <NotificationBell preferences={notificationPreferences} />
  //);
};
