export const fileTypeMimes: FileTypeMimes = {
  documents: [
    'application/vnd.oasis.opendocument.text',
    'application/vnd.oasis.opendocument.text-template',
    'application/vnd.oasis.opendocument.text-flat-xml',
    'application/vnd.oasis.opendocument.text-web',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
    'application/vnd.ms-word.document.macroEnabled.12',
    'text/xml',
    'application/msword',
    'text/html',
    'text/rtf',
    'text/plain',
  ],
  images: [
    'image/vnd.net-fpx',
    'image/x-portable-bitmap',
    'image/x-pcx',
    'image/x-portable-graymap',
    'image/x-pict',
    'image/png',
    'image/x-portable-anymap',
    'image/x-portable-pixmap',
    'image/vnd.adobe.photoshop',
    'image/x-cmu-raster',
    'image/x-rgb',
    'image/vnd.fujixerox.edmics-rlc',
    'image/svg+xml',
    'image/tiff',
    'image/x-xbitmap',
    'image/vnd.xiff',
    'image/x-xpixmap',
    'image/x-xwindowdump',
    'image/bmp',
    'image/cgm',
    'image/x-cmx',
    'image/vnd.djvu',
    'image/vnd.dwg',
    'image/vnd.dxf',
    'image/gif',
    'image/vnd.fastbidsheet',
    'image/x-freehand',
    'image/vnd.fpx',
    'image/vnd.fst',
    'image/g3fax',
    'image/x-icon',
    'image/ief',
    'image/jpeg',
    'image/vnd.ms-modi',
    'image/vnd.fujixerox.edmics-mmr',
  ],
  audio: [
    'audio/adpcm',
    'audio/x-aiff',
    'audio/vnd.dts',
    'audio/vnd.digital-winds',
    'audio/vnd.lucent.voice',
    'audio/x-mpegurl',
    'audio/mpeg',
    'audio/mp4',
    'audio/ogg',
    'audio/vnd.ms-playready.media.pya',
    'audio/x-pn-realaudio',
    'audio/midi',
    'audio/x-pn-realaudio-plugin',
    'audio/basic',
    'audio/x-ms-wax',
    'audio/x-ms-wma',
  ],
  videos: [
    'video/x-ms-asf',
    'video/x-msvideo',
    'video/x-f4v',
    'video/x-fli',
    'video/x-flv',
    'video/vnd.fvt',
    'video/h261',
    'video/h263',
    'video/h264',
    'video/jpm',
    'video/vnd.mpegurl',
    'video/x-m4v',
    'video/mj2',
    'video/mpeg',
    'video/mp4',
    'video/ogg',
    'video/vnd.ms-playready.media.pyv',
    'video/quicktime',
    'video/vnd.vivo',
    'video/x-ms-wm',
    'video/x-ms-wmv',
    'video/x-ms-wmx',
    'video/x-ms-wvx',
  ],
  archives: [
    'application/zip',
    'application/rar',
    'application/x-rar-compressed',
    'application/x-7z-compressed',
    'application/x-ace-compressed',
    'application/x-arj',
    'application/x-bzip',
    'application/x-bzip2',
    'application/x-cab-compressed',
    'application/x-chm',
    'application/x-cpio',
    'application/x-deb',
    'application/x-dgc-compressed',
    'application/x-gzip',
    'application/x-lha',
    'application/x-lzh',
    'application/x-lzx',
    'application/x-rar',
    'application/x-rpm',
    'application/x-tar',
    'application/x-xz',
    'application/x-xar',
  ],
  pdf: [
    'application/pdf',
    'application/x-pdf',
    'application/acrobat',
    'applications/vnd.pdf',
    'text/pdf',
    'text/x-pdf',
  ],
  slides: [
    'application/vnd.oasis.opendocument.presentation-template',
    'application/vnd.oasis.opendocument.graphics',
    'application/vnd.oasis.opendocument.presentation-flat-xml',
    'application/vnd.oasis.opendocument.presentation-web',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
    'application/vnd.openxmlformats-officedocument.presentationml.template',
    'application/vnd.oasis.opendocument.presentation',
    'application/vnd.ms-powerpoint',
    'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
  ],
  spreadsheets: [
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/vnd.oasis.opendocument.spreadsheet-template',
    'application/vnd.oasis.opendocument.spreadsheet-flat-xml',
    'application/vnd.oasis.opendocument.spreadsheet-web',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
    'application/vnd.ms-excel',
    'application/vnd.fdsn.mseed',
    'application/vnd.dbf',
    'application/softgrid-slk',
    'text/csv',
    'application/vnd.ms-excel.sheet.macroEnabled.12',
  ],
  links: ['text/uri-list'],
};

export type FileTypeMimes = {
  slides: string[];
  spreadsheets: string[];
  images: string[];
  audio: string[];
  videos: string[];
  archives: string[];
  pdf: string[];
  documents: string[];
  links: string[];
};
